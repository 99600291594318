import { BackgroundEndDesktop, BackgroundEndMobile, ModalEnd } from './styled'

const App = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <BackgroundEndMobile
        src="img/background-end-mobile.jpg"
        alt="Promotion ends mobile"
      />
      <BackgroundEndDesktop
        src="img/background-end-desktop.jpg"
        alt="Promotion ends desktop"
      />
    </div>
  )
}

export default App
